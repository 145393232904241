import axios from '@axios'

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    fetchAllGame(ctx, id) {
      return new Promise((resolve, reject) => {
        axios
          .get(`${process.env.VUE_APP_API_URL}/game/all/${id}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchCompanies(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .get(`${process.env.VUE_APP_API_URL}/company/all/pagination/${queryParams.page}`, { params: queryParams })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchCompany(ctx, id) {
      return new Promise((resolve, reject) => {
        axios
          .get(`${process.env.VUE_APP_API_URL}/companies/${id}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchCompanyGames(ctx, id) {
      return new Promise((resolve, reject) => {
        axios
          .get(`${process.env.VUE_APP_API_URL}/competition/company/${id}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchCompanyUsers(ctx, id) {
      return new Promise((resolve, reject) => {
        axios
          .get(`${process.env.VUE_APP_API_URL}/user/get/company/${id}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    addCompany(ctx, data) {
      return new Promise((resolve, reject) => {
        axios
          .post(`${process.env.VUE_APP_API_URL}/companies`, data)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    updateCompany(ctx, data) {
      return new Promise((resolve, reject) => {
        axios
          .put(`${process.env.VUE_APP_API_URL}/companies/${data.companyID}`, data)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchCompaniesAll() {
      return new Promise((resolve, reject) => {
        axios
          .get(`${process.env.VUE_APP_API_URL}/company/all`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
  },
}
