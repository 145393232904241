<template>
  <b-card
    v-if="tableData"
    no-body
    class="card-company-table"
  >
    <h6
      v-if="tableData.length>0"
      class="mt-0 pt-50"
    >
      {{ $t('USERS') }}
    </h6>
    <b-table
      :items="tableData"
      responsive
      :fields="userTableColumns"
      :empty-text="$t('NO_MATCHING_RECORDS_FOUND')"
      primary-key="id"
      class="mb-0"
    >

      <!-- Name -->
      <template #cell(name)="data">
        <div class="d-flex align-items-center">
          <span class="font-weight-bolder mr-1">{{ data.item.firstname }} {{ data.item.lastname }}</span>
        </div>
      </template>

      <!-- Email -->
      <template #cell(email)="data">
        <div class="d-flex align-items-center">
          <div>
            <div class="font-weight-bolder">
              <span>{{ data.item.email }}</span>
            </div>
          </div>
        </div>
      </template>
      <!-- Phone -->
      <template #cell(phone)="data">
        <div class="d-flex align-items-center">
          <div>
            <div class="font-weight-bolder">
              <span>{{ data.item.phonenumber }}</span>
            </div>
          </div>
        </div>
      </template>
      <!-- Role -->
      <template #cell(role)="data">
        <div class="text-nowrap">
          <feather-icon
            :icon="resolveUserRole(data.item.userRoleId).Icon"
            size="18"
            class="mr-50"
            :class="`text-${resolveUserRole(data.item.userRoleId).variant}`"
          />
          <span class="align-text-top text-capitalize">{{ resolveUserRole(data.item.userRoleId).name }}</span>
        </div>
      </template>
      <!-- Column: Actions -->
      <template #cell(actions)="data">
        <div class="text-nowrap">
          <feather-icon
            :id="`game-row-${data.item.userID}-preview-icon`"
            icon="EyeIcon"
            size="16"
            class="mx-1 cursor-pointer"
            @click="$router.push({ name: 'user-profile-id', params: { id: data.item.userID }})"
          />
        </div>
      </template>
    </b-table>
  </b-card>
</template>

<script>
import { BCard, BTable } from 'bootstrap-vue'
import { resolveUserRole } from '@/constants/utils'

export default {
  components: {
    BCard,
    BTable,
  },
  props: {
    tableData: {
      type: Array,
      default: () => [],
    },
  },
  setup() {
    const userTableColumns = [
      { key: 'NAME' },
      { key: 'EMAIL' },
      { key: 'PHONE' },
      { key: 'ROLE' },
      { key: 'ACTIONS' },
    ]

    return {
      userTableColumns,
      resolveUserRole,
    }
  },

}

</script>

<style lang="scss" scoped>
@import '~@core/scss/base/bootstrap-extended/include';
@import '~@core/scss/base/components/variables-dark';

.card-company-table ::v-deep td .b-avatar.badge-light-company {
  .dark-layout & {
    background: $theme-dark-body-bg !important;
  }
}
</style>
