<template>
  <b-card
    v-if="tableData"
    no-body
    class="card-company-table"
  >
    <h6
      v-if="tableData.length>0"
      class="mt-0 pt-50"
    >
      {{ $t('GAMES') }}
    </h6>
    <b-table
      :items="tableData"
      responsive
      :fields="tableColumns"
      :empty-text="$t('NO_MATCHING_RECORDS_FOUND')"
      primary-key="id"
      class="mb-0"
    >

      <!-- name -->
      <template #cell(name)="data">
        <b-media vertical-align="center">
          <template #aside>
            <feather-icon
              icon="AwardIcon"
              size="18"
              class="mr-50 text-primary"
            />
          </template>
          <b-link
            :to="{ name: 'game-view', params: { id: data.item.encrypt_id } }"
            class="font-weight-bold d-block text-nowrap"
          >
            {{ strSubstring(data.item.c_string_swe,20) }}
          </b-link>
        </b-media>
      </template>

      <!-- email -->
      <template #cell(period)="data">
        <div class="d-flex align-items-center">
          <div>
            <div class="font-weight-bolder">
              <span>{{ formatSqlDate(data.item.startdate) }} to {{ formatSqlDate(data.item.enddate) }}</span>
            </div>
          </div>
        </div>
      </template>
      <!-- Column:StartDate -->
      <template #cell(StartDate)="data">
        <b-media vertical-align="center">
          <span class="font-weight-bold d-block text-nowrap">
            {{ formatDate(data.item.startdate) }}
          </span>
        </b-media>
      </template>
      <!-- Column:EndDate -->
      <template #cell(EndDate)="data">
        <b-media vertical-align="center">
          <span class="font-weight-bold d-block text-nowrap">
            {{ formatDate(data.item.enddate) }}
          </span>
        </b-media>
      </template>
      <!-- Column: Status -->
      <template #cell(Status)="data">
        <b-badge
          pill
          :variant="`light-${resolveGameStatusVariant(data.item).variant}`"
          class="text-capitalize"
        >
          {{ resolveGameStatusVariant(data.item).text }}
        </b-badge>
      </template>
      <!-- Column: Actions -->
      <template #cell(actions)="data">
        <div class="text-nowrap">
          <feather-icon
            :id="`game-row-${data.item.encrypt_id}-preview-icon`"
            icon="EyeIcon"
            size="16"
            class="mx-1 cursor-pointer"
            @click="$router.push({ name: 'game-view', params: { id: data.item.encrypt_id }})"
          />
        </div>
      </template>
    </b-table>
  </b-card>
</template>

<script>
import {
  BCard, BTable, BLink,
  BMedia, BBadge,
} from 'bootstrap-vue'
// eslint-disable-next-line import/extensions, import/no-unresolved
import { mixinDate } from '@/constants/mixinDate'
import { mixinList } from '@/constants/mixinValidations'
import useGameList from '../../games/game-list/useGameList'

export default {
  components: {
    BCard,
    BTable,
    BLink,
    BMedia,
    BBadge,
  },
  mixins: [mixinDate, mixinList],
  props: {
    tableData: {
      type: Array,
      default: () => [],
    },
  },
  setup() {
    const tableColumns = [
      { key: 'Name' },
      { key: 'StartDate' },
      { key: 'EndDate' },
      { key: 'Status' },
      { key: 'Actions' },
    ]

    const {
      resolveGameStatusVariant,
    } = useGameList()

    return {
      tableColumns,
      resolveGameStatusVariant,
    }
  },

}

</script>

<style lang="scss" scoped>
@import '~@core/scss/base/bootstrap-extended/include';
@import '~@core/scss/base/components/variables-dark';

.card-company-table ::v-deep td .b-avatar.badge-light-company {
  .dark-layout & {
    background: $theme-dark-body-bg !important;
  }
}
</style>
